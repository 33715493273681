.pb-drawer-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 520px;
  overflow: hidden;
  height: 100%;
}

.pb-drawer-header {
  height: 51px;
  border-bottom: 1px solid #d4d4d5;
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
}

.pb-drawer-header .btn-header-close {
  cursor: pointer;
}

.pb-drawer-content {
  height: 100%;
  padding: 16px;
  height: 100%;
  overflow: overlay;
}
